<template>
    <zw-sidebar @shown="shown" :title="$t('common.title.qr')">
        <canvas ref="qrCanvas"></canvas>

        <zw-input-group size="sm" disable-label v-model="payload" name="value">
            <template #append-slot>
                <b-input-group-append>
                    <b-button variant="info"
                              size="sm"
                              @click="$root.copyToClipboard(payload)"
                    >
                        <font-awesome-icon icon="copy"/>
                    </b-button>
                </b-input-group-append>
            </template>
        </zw-input-group>
    </zw-sidebar>
</template>

<script>
import QRCode from 'qrcode';

export default {
    name: 'QRModal',
    data() {
        return {
            payload: {},
        }
    },
    methods: {
        shown() {
            const canvas = this.$refs.qrCanvas;
            QRCode.toCanvas(canvas, this.payload, {errorCorrectionLevel: 'H'});
        }
    },
}
</script>